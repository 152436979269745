@import "~antd/lib/style/themes/index.less";
@import "~hive-admin/src/antd.less";

@drone-mapping-primary: #f01f37;
@drone-mapping-primary-faded-85: rgba(@drone-mapping-primary, 0.85);
@drone-mapping-primary-faded-80: rgba(@drone-mapping-primary, 0.8);
@drone-mapping-black: #2D2C47;
@drone-mapping-black-faded: rgba(@drone-mapping-black, 0.8);

@primary-color: @drone-mapping-primary;

@heading-color: @drone-mapping-primary;
@text-color: @drone-mapping-black-faded;

@border-radius-base: 16px;

@font-family:
  "Chinese Quote",
  "Avenir Next",
  "Inter",
  "Helvetica Neue",
  -apple-system,
  BlinkMacSystemFont,
  "Segoe UI",
  "PingFang SC",
  "Hiragino Sans GB",
  "Microsoft YaHei",
  Helvetica,
  Arial,
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol";

@includeLessVars: {
  display: none !important;
  @vars: primary-color,primary-1,primary-2,background-color-light,text-color,disabled-color,error-color,red-2,border-color-base,border-radius-base;
  .-(@i: length(@vars)) when (@i > 0)
  {
    @name: extract(@vars, @i);
    --@{name}: @@name !important;
    .-((@i - 1));
  }
  .-;
}

@import '~antd/lib/style/core/index.less';
@import '~antd/lib/style/components.less';
@import '~hive-admin/src/components/PageSmallForm.less';

#__LESSVARS__ {
  @includeLessVars();
}

[data-component="PageSignup"] {
  &:extend([data-component="PageSmallForm"] all);
}

#admin {
  [data-component="PageSignup"],
  [data-component="PagePasswordReset"],
  [data-component="PageLogin"] {
    .small-form-wrapper {
      padding: 0px;
      border-radius: 0px;
      box-shadow: none;
      .field-address {
        margin-top: 4px;
      }
    }
  }

  #sidebar .ant-menu-item {
    &:after { display: none !important }
    border-radius: 50px !important;
  }

  &[data-is-tablet="true"] {
    .ant-btn[data-action]:not([data-action="upload"]) {
      border-radius: @border-radius-base * 1.5;
    }
  }

  .ant-form-item {
    margin-bottom: 10px;

    h1 {
      margin-top: 12px;
      margin-bottom: 2px;
    }
  }

  .ant-divider {
    margin-top: 16px !important;
  }

  @media (min-width: 1024px) {
    .ant-form-item {
      margin-bottom: 18px;

      h1 {
        margin-top: 10px;
        margin-bottom: 6px;
      }
    }

    .ant-divider {
      margin-top: 12px !important;
      margin-bottom: 30px !important;
    }
  }

  h1,
  #main #content > [data-component] > .page-header .page-header-left .page-header-title {
    font-weight: 900;
    color: @drone-mapping-black;
    opacity: 1;

    * {
      opacity: 1;
    }
  }

  .ant-input-lg {
    border-radius: @border-radius-base * 2 !important;
  }

  [data-component="PageLogin"] [data-action="login"],
  [data-component="PageSignup"] [data-action="signup"],
  [data-component="PagePasswordReset"] [data-action="password-reset"] {
    font-size: 18px !important;

    @media (min-width: 768px) {
      font-size: 16px !important;
    }
  }

  [data-component="PageSingle"] > form > .ant-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    label[for="details.image"]:before {
      content: "" !important;
    }
  }

  .ant-divider.ant-divider-horizontal:not(
    .ant-menu .ant-divider,
    .actions-bottom .ant-divider
  ) {
    display: none;
  }
}
