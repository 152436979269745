@import "~antd/lib/style/themes/index.less";
@import "~hive-admin/src/antd.less";

@drone-mapping-primary: #f01f37;
@drone-mapping-primary-faded-85: rgba(@drone-mapping-primary, 0.85);
@drone-mapping-primary-faded-80: rgba(@drone-mapping-primary, 0.8);
@drone-mapping-black: #2D2C47;
@drone-mapping-black-faded: rgba(@drone-mapping-black, 0.8);

@primary-color: @drone-mapping-primary;

@heading-color: @drone-mapping-primary;
@text-color: @drone-mapping-black-faded;

@border-radius-base: 16px;

@font-family:
  "Chinese Quote",
  "Avenir Next",
  "Inter",
  "Helvetica Neue",
  -apple-system,
  BlinkMacSystemFont,
  "Segoe UI",
  "PingFang SC",
  "Hiragino Sans GB",
  "Microsoft YaHei",
  Helvetica,
  Arial,
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol";

[data-component="PageArchiveTable"] {
  .ant-table-scroll {
    position: relative;

    overflow-x: initial;
    overflow-y: initial;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0; bottom: 0;
      width: 40px;
    }

    &:before {
      left: -40px;
      background: linear-gradient(to right, white, transparent);
    }

    &:after {
      right: -40px;
      background: linear-gradient(to left, white, transparent);
    }
  }

  .ant-table-body {
    margin: 0 -40px 5px;
    padding: 0 40px 15px;
  }

  table {
    border: 1px solid #d9d9d9 !important;
    border-radius: 10px;
    overflow: hidden;

    th:last-of-type,
    td:last-of-type {
      border-right: none;
    }

    tr:last-of-type td {
      border-bottom: none;
    }
  }
}
